// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


import '../../assets/stylesheets/stylesheets.js'
require('bootstrap');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

(function () {
    'use strict'

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        })
})()

document.addEventListener("turbolinks:load", function() {
    let $ = require('jquery');
    window.$ = window.jQuery = $;

    (function () {
        'use strict'

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            })
    })()


    $(document).ready(function (e) {
        document.getElementById("contact-form-switch").addEventListener("click", function (event) {
            const contactForm = document.getElementById('contact-form');
            const contactFormSwitch = document.getElementById('contact-form-switch');
            contactForm.classList.remove('d-none');
            contactFormSwitch.classList.add('d-none');
        })
    })


});